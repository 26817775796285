<template>
  <div class="detail">
    <div class="ltnav">
      <b-breadcrumb :items="navitems" class="container"></b-breadcrumb>
    </div>
    <div class="maintext container">
      <div class="title">
        <h3>{{ list.title }}</h3>
        <span>{{ list.date }}</span>
      </div>
      <div class="content" v-html="list.text"></div>
    </div>
  </div>
</template>

<script>
import newsLists from "../../assets/json/news";
export default {
  data() {
    return {
      navitems: [
        {
          text: "首页",
          href: "/",
        },
        {
          text: "新闻动态",
          href: "#/new",
        },
        {
          text: "新闻详情",
          active: false,
        },
      ],
      newsArr: [],
      list: {},
    };
  },
  created() {
    this.list = newsLists.newsLists[this.$route.query.id];
    console.log(this.newsArr[this.$route.query.id]);
  },
};
</script>

<style lang="scss" scoped>
.detail {
  text-align: left;
  margin-top: 80px;
  height: max-content;
  margin-bottom: 20rem;
}
.ltnav {
  padding: 2rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: ">";
  }
  .breadcrumb {
    background-color: #fff;
    margin-bottom: unset;
    // padding-left: 6rem;
  }
  a {
    color: #666666;
  }
}
.maintext {
  margin-top: 50px;
  box-shadow: 10px 20px 60px 0px rgb(49 199 223 / 15%);
  padding-top: 1rem;
  padding-bottom: 3rem;
}
.title {
  margin: 4rem 7rem 2rem;
  h3 {
    font-weight: 500;
    font-size: 30px;
  }
  span {
    line-height: 40px;
    color: #999;
  }
}
.content::v-deep {
  .imgs {
    margin: 3rem auto;
    width: 70%;
    img {
      width: 100%;
      display: block;
    }
  }
  p {
    text-indent: 2em;
    color: #7f7f7f;
    padding: 0 7rem;
    font-weight: lighter;
    line-height: 2rem;
    font-size: 17px;
    margin-bottom: 2rem;
  }
}
@media (max-width: 768px) {
  .maintext {
    margin-top: 0px;
  }
  .detail {
    margin-top: 3.75rem;
    margin-bottom: 4rem;
  }
  .ltnav {
    display: none;
    padding: 0rem;
    .breadcrumb {
      padding-left: 1rem;
    }
  }
  .title {
    margin: 0rem 0rem;
    h3 {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0rem;
    }
    span {
      font-size: 12px;
      color: #999;
    }
  }
  .content::v-deep {
    p {
      padding: 0px 0px;
      margin-bottom: 0rem;
      font-size: 14px;
      line-height: 22px;
    }
    .imgs {
      margin: 1rem auto;
    }
  }
}
</style>